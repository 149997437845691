import React, {useState} from "react";
import Routing from "./views/routes/Routing";
import {AppContext} from "./context/AppContext";

const App = () => {

  const [isDarkMode, setIsDarkMode] = useState(() => true);

  const toggleDarkMode = () => {
    if(isDarkMode){
      setIsDarkMode(false)
    }
    else{
      setIsDarkMode(true)
    }
  }

  return (
    <AppContext.Provider value={{isDarkMode, setIsDarkMode}} style={{minWidth: "475px"}}>
      <Routing />
    </AppContext.Provider>
  );
}

export default App;
