import React from "react"
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import StandardRouting from "./StandardRouting";

const Routing = () => {

  return (
      <Router>
        <StandardRouting />
      </Router>
  )
}

export default Routing;