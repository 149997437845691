import React, {useEffect, useState} from "react"
import SharedStyles from "./css/_SharedStyles.module.scss";
import Gif from "../../assets/gifs/NoddingMan.gif"

const Fuck = () => {

  const [isFucked, setIsFucked] = useState(false);

  useEffect(() => {
    if(isFucked === true){
      document.getElementById('noddingMan').src=Gif;
      setTimeout(() => {
        setIsFucked(() => false);
        document.getElementById('noddingMan').src="";
      }, 5000)
    }
  }, [isFucked])

  return (
      <div className={SharedStyles.DivBackgroundWrapper}>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "93vh"}}>
          <a hidden={isFucked} style={{fontSize: "78px", color: "goldenrod", cursor: "pointer", textShadow: "-3px 0 whitesmoke, 0 3px whitesmoke, 3px 0 whitesmoke, 0 -3px whitesmoke", fontFamily: "Felix Titling", userSelect: "none"}}
             onClick={() => setIsFucked(true)}>Fuck</a>
          <img id="noddingMan" hidden={!isFucked} onAnimationEnd={() => setIsFucked(true)} src={Gif}
            style={{border: "4px solid goldenrod", userSelect: "none"}}
          />
        </div>
      </div>
  )
}

export default Fuck;