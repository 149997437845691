import React, {useState} from "react";
import Styles from "./css/StandardRouting.module.scss";
import SharedStyles from "../standard/css/_SharedStyles.module.scss"
import Classes from "../../css/Classes.module.scss";
import classNames from "classnames";
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import HomePage from "../standard/HomePage";
import Fuck from "../standard/Fuck";
import Roadmap from "../standard/Roadmap";
import {ButtonGroup, Nav, Navbar, NavItem, ToggleButton} from "react-bootstrap";
import logo from "../../assets/logos/panda-logo-4-1000.png";
import DarkModeToggle from "react-dark-mode-toggle"
import FAQ from "../standard/FAQ";


const StandardRouting = (props) => {

  const navigate = useNavigate();
  const [isDarkMode, setIsDarkMode] = useState(() => false);

  const toggleDarkMode = () => {
    if(isDarkMode){
      setIsDarkMode(false)
    }
    else{
      setIsDarkMode(true)
    }
  }

  return (
      <div>
        <Navbar variant="dark" sticky="top" className={Styles.Navbar}>
          <Navbar.Brand style={{marginLeft: "1vw"}}>
            <img
                alt=""
                src={logo}
                width="50"
                height="50"
                className={classNames(Styles.Logo)}
                onClick={() => navigate("/")}
            />
          </Navbar.Brand>
          <Nav >

          </Nav>
          <Nav >
            <Nav.Link className={classNames(Classes.buttonClash)} onClick={() => navigate("/")}>Home</Nav.Link>
            <Nav.Link className={classNames(Classes.buttonClash)} onClick={() => navigate("/dbd/randomizer")}>Randomizer</Nav.Link>
            <Nav.Link className={classNames(Classes.buttonClash)} onClick={() => navigate("/i/roadmap")}>Roadmap</Nav.Link>
            <Nav.Link className={classNames(Classes.buttonClash)} onClick={() => navigate("/fuck")}>Firetruck</Nav.Link>
          </Nav>
          {/*<Nav className="ms-auto" style={{marginRight: "1vw"}} onClick={() => window.location="/roadmap"}>
            <Nav.Link className={classNames(Classes.buttonClash)}>FAQ</Nav.Link>
          </Nav>*/}
          <Nav className="ms-auto align-items-center" style={{marginRight: "1vw"}}>
            <NavItem style={{marginRight: "1vw"}}>
              <DarkModeToggle
                  onChange={toggleDarkMode}
                  checked={isDarkMode}
                  size={40}
              />
            </NavItem>
            <Nav.Link className={classNames(Classes.buttonClash)} onClick={() => navigate("/i/faq")}>FAQ</Nav.Link>
          </Nav>
        </Navbar>
        <Routes>
          <Route exact path="/" element={<HomePage />}/>
          <Route exact path="/fuck" element={<Fuck />}/>
          <Route exact path="/i/roadmap" element={<Roadmap />}/>
          <Route exact path="/i/faq" element={<FAQ />}/>
        </Routes>
      </div>
  )
}

export default StandardRouting;