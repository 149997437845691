import React from "react"
import Styles from "./css/FAQ.module.scss"
import SharedStyles from "./css/_SharedStyles.module.scss";
import classNames from "classnames";

const FAQ = () => {


  return (
    <div className={classNames(SharedStyles.DivBackgroundWrapper, Styles.FAQWrapper)}>
      <header style={{fontSize: "calc(5px + 3vmin)"}}>Credits</header>
      <a>White Gold Background : Image by <a href="https://www.freepik.com/free-vector/gradient-luxurious-background_14666783.htm#query=white%20gold%20wallpaper&position=1&from_view=keyword">Freepik</a></a>
    </div>
  )
}

export default FAQ;