import React from "react";
import {Table} from "react-bootstrap";
import Styles from "./css/Roadmap.module.scss"
import SharedStyles from "./css/_SharedStyles.module.scss"

const Roadmap = () => {

  return (
      <div className={SharedStyles.DivBackgroundWrapper}>
        <div className={Styles.TableShell}>
          <Table bordered hover className={Styles.Table}>
            <thead>
              <tr>
                <th>Priority</th>
                <th>Objective</th>
                <th>Progress</th>
              </tr>
            </thead>
            <tbody>
            <tr>
              <td>1</td>
              <td>Get this roadmap done</td>
              <td>[50%] - I have most of the styling done already, just need the tasks</td>
            </tr>
            <tr>
              <td>1</td>
              <td>Fill me!</td>
              <td>[0%]</td>
            </tr>
            <tr>
              <td>1</td>
              <td>You're a wizard Panda!</td>
              <td>[25%]</td>
            </tr>
            <tr>
              <td>1</td>
              <td>Meet gandalf!</td>
              <td>[-10%]</td>
            </tr>
            </tbody>
          </Table>
        </div>
      </div>
  )
}

export default Roadmap;