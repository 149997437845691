import React from "react"
import logo from "../../assets/logos/panda-logo-2-1000.png";
import Styles from "./css/HomePage.module.scss"
import classNames from "classnames";
import SharedStyles from "./css/_SharedStyles.module.scss";

const HomePage = () => {


  return (
      <div className={classNames(Styles.HomePage, SharedStyles.DivBackgroundWrapper)}>
        <div className={Styles.HomePageHeader}>
          <div className={Styles.HomePageHeaderDiv}>
            <img src={logo} className={Styles.HomePageLogo} alt="logo" style={{minHeight: "100px", minWidth: "100px"}} />
            <a>Work in progress</a>
          </div>
        </div>
      </div>
  )
}

export default HomePage;